import React, { useState, useEffect } from "react";
import "./homepage.css";
import authService from "../../services/auth.service";
import projectService from "../../services/project.service";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading";
import { Table } from "react-bootstrap";
import { titleCase, notifIconConverter } from "../../services/helper";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import userService from "../../services/user.service";
import notificationService from "../../services/notification.service";
import FontAwesome from "react-fontawesome";
import UpcomingProjectModal from "../../components/UpcomingProjectModal";

const FadeAnimation = styled.div`
  animation: 0.5s ${keyframes`${fadeInUp}`};
`;
function HomePage() {
  const navigate = useNavigate();
  const user = authService.getCurrentUser();
  const [projects, setProjects] = useState([]);
  const [upcomingProjects, setUpcomingProjects] = useState([]);
  const [pNotifications, setPNotifications] = useState([]);
  const [mNotifications, setMNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [visuallyHidden, setVisuallyHidden] = useState(true);
  const loading = false;

  useEffect(() => {
    if (user) {
      notificationService.getBellNotifs({ uid: user.id }).then((res) => {
        console.log(res.data);
        setNotifications(res.data);
      });
    }
  }, []);
  useEffect(() => {
    if (user) {
      projectService.getProjects({ id: user.id }).then((res) => {
        let toBeSorted = [...res.data];

        toBeSorted.sort((a, b) => {
          let aDate = new Date(a.estimated_end_date);
          let bDate = new Date(b.estimated_end_date);
          return aDate == "Invalid Date" || bDate === "Invalid Date"
            ? 1
            : bDate > aDate
            ? 1
            : bDate < aDate
            ? -1
            : 0;
        });
        setProjects(toBeSorted);
        userService
          .getNotifications({ uid: user.id, name: user.name })
          .then((notifs) => {
            let tempM = [];
            let tempP = [];
            notifs.data.projects.forEach((n) => {
              if (
                n.status.toLowerCase() != "complete" ||
                n.status.toLowerCase() != "completed"
              )
                tempP.push(n);
            });
            notifs.data.milestones.forEach((n) => {
              if (
                n.status.toLowerCase() != "complete" ||
                n.status.toLowerCase() != "completed"
              )
                tempM.push(n);
            });
            setMNotifications(tempM);
            setPNotifications(tempP);
          });
      });
    }
    // setTimeout(() => {
    //   setLoading(false);
    // }, 700);
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "add-project",
      JSON.stringify(upcomingProjects)
    );
  });

  useEffect(() => {
    const data = localStorage.getItem("add-project");
    setUpcomingProjects(JSON.parse(data));
  }, []);

  const boxAnimation = () => {
    if (showBox) {
      setShowBox(false);
      setTimeout(() => {
        setVisuallyHidden(true);
      }, 200);
    } else {
      setVisuallyHidden(false);
      setShowBox(true);
    }
  };
  const removeNotif = (e, notifId) => {
    e.preventDefault();
    notificationService.deleteNotification({ notifId }).then(() => {
      console.log("deleted");
      let temp = [...notifications];
      temp = temp.filter((n) => n.id != notifId);
      setNotifications(temp);
    });
  };
  // const handleClick = () => {
  //   console.log("clicked");
  // };

  return (
    <div className={`home-container`}>
      <Sidebar />

      {loading ? (
        <Loading />
      ) : (
        <div className="home-content-container">
          <div className="ms-header">
            <h1 className="welcome-header header">
              Welcome Back {titleCase(user.name)}
            </h1>
            <div className="notif-group far-right">
              {notifications.length > 0 ? <p className="bell-num"></p> : null}
              <FontAwesome
                onClick={boxAnimation}
                color="ffff"
                className="pointer"
                size="lg"
                name="bell"
              />
              <div
                className={`${showBox ? "showbox" : "hidebox"} ${
                  visuallyHidden ? "visually-hidden" : null
                } notification-container`}
              >
                <h5>Recent Notifications</h5>
                <hr />
                {notifications.map((n) => {
                  if (n.milestone) {
                    return (
                      <div
                        key={n.id}
                        className="notif-item"
                        onContextMenu={(e) => removeNotif(e, n.id)}
                        onClick={() =>
                          navigate(`/project-details/${n.milestone.project.id}`)
                        }
                      >
                        <span className="notif-hand-container">
                          <FontAwesome
                            name={notifIconConverter[n.notification_type]}
                          />
                        </span>
                        <span className="notif-ms-name">
                          <p>{n.milestone.milestone_name}</p>
                        </span>
                        <span className="notif-proj-name">
                          <p>{n.milestone.project.project_name}</p>
                        </span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>

          <FadeAnimation>
            <div className={` projects-container`}>
              <h1 className="upcoming-proj-header sub-header">
                Upcoming Projects
                <hr className="accent-bg purp-line" />
              </h1>

              <div className="card-container">
                {upcomingProjects.map((project, i) => {
                  if (i < 5) {
                    let pNameStripped = project.name.replace(
                      /^(.{11}[^\s]*).*/,
                      "$1"
                    );
                    pNameStripped != project.project_name
                      ? (pNameStripped += "...")
                      : (pNameStripped = pNameStripped);
                    return (
                      <div
                        onClick={() =>
                          navigate(`/project-details/${project.id}`)
                        }
                        key={i}
                        className="project-card"
                      >
                        <p className="card-header">{pNameStripped}</p>
                        <p className="card-date">
                          {project.estimated_end_date}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="card-container project-card">
                <p className="card-header">Add to Dashboard</p>
                <UpcomingProjectModal
                  upcomingProjects={upcomingProjects}
                  setUpcomingProjects={setUpcomingProjects}
                />
              </div>
            </div>
          </FadeAnimation>
          <FadeAnimation>
            {/* <Pi /> */}
            <div className="task-container">
              <h1 className="sub-header">
                Recent Activity
                <hr className="accent-bg purp-line" />
              </h1>
              <div className="activity-container">
                <div className="scroll-y mx-2">
                  <h3>Assigned Projects</h3>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Date </th>
                        <th>Project</th>
                      </tr>
                    </thead>
                    <tbody className="t-body-proj">
                      {pNotifications.map((item) => {
                        let formattedDate = new Date(item.createdAt);
                        let month = formattedDate.getMonth() + 1;
                        let day = formattedDate.getDate();
                        let year = formattedDate.getFullYear();
                        let totalString = `${month}-${day}-${year}`;
                        return (
                          <tr
                            onClick={() =>
                              navigate(`/project-details/${item.id}`)
                            }
                            className="pointer hover-purp recent-project-row"
                          >
                            <td>{totalString}</td>
                            <td>{item.project_name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="scroll-y">
                  <h3>Recent Milestones</h3>
                  <Table>
                    <thead>
                      {" "}
                      <tr>
                        <th style={{ width: "20%" }}>Date </th>
                        <th>Milestone</th>
                        <th>Project</th>
                      </tr>
                    </thead>
                    <tbody className="t-body-ms">
                      {mNotifications.map((item) => {
                        let formattedDate = new Date(item.createdAt);
                        let month = formattedDate.getMonth() + 1;
                        let day = formattedDate.getDate();
                        let year = formattedDate.getFullYear();
                        let totalString = `${month}-${day}-${year}`;
                        return (
                          <tr
                            onClick={() =>
                              navigate(`/project-details/${item.project_id}`)
                            }
                            className="pointer hover-purp recent-ms-row"
                          >
                            <td>{totalString}</td>
                            <td>{item.milestone_name}</td>
                            <td>
                              {projects.map((p) => {
                                return p.id == item.project_id
                                  ? p.project_name
                                  : null;
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </FadeAnimation>
        </div>
      )}
    </div>
  );
}
export default HomePage;
