import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/"
  : "https://myomis.herokuapp.com/api/";

class TimeService {
  submitTime(data) {
    return axios.post(API_URL + "time/new", data, { headers: authHeader() });
  }
  getTimeSheetsByUser(user) {
    return axios.post(API_URL + "time", user, { headers: authHeader() });
  }
  getSheetByWeek(week) {
    return axios.post(API_URL + "time/single", week, { headers: authHeader() });
  }
  getWeekEndings(user) {
    return axios.post(API_URL + "time/week-endings", user, {
      headers: authHeader(),
    });
  }
  getAllByWeek(week) {
    return axios.post(API_URL + "time/all-by-week", week, {
      headers: authHeader(),
    });
  }
  getStatsByUser(user) {
    return axios.post(API_URL + "/time/stats", user, {
      headers: authHeader(),
    });
  }
  adminSingle(data) {
    return axios.post(API_URL + "time/admin-single", data, {
      headers: authHeader(),
    });
  }
  timeStamp(data) {
    return axios.post(API_URL + "time/stamp", data, {
      headers: authHeader(),
    });
  }
  getTimesByProject(project) {
    return axios.post(API_URL + "time/by-project", project, {
      headers: authHeader(),
    });
  }
  newTime(data) {
    return axios.post(API_URL + "time/new-time", data, {
      headers: authHeader(),
    });
  }

  addToPto(data) {
    /*
      data must be formatted 
      {
        name: {company: hours}
      }
    */
    return axios.post(API_URL + "time/add-to-pto", data, {
      headers: authHeader(),
    });
  }

  setAdded(startDate, endDate) {
    const data = { startDate: startDate, endDate: endDate };
    return axios.post(API_URL + "time/set-added", data, {
      headers: authHeader(),
    });
  }
}

export default new TimeService();
