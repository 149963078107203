import CustomRouter from "./services/CustomRouter";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import authService from "./services/auth.service";
import ThemeWrapper from "./components/ThemeWrapper";
import { useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  function setPathname() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    } else if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  }

  function logout() {
    authService.logout();
    navigate("/login");
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    } else if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  }, []);

  return (
    <div onChange={setPathname} className="app">
      <ThemeWrapper />
      <CustomRouter setPathname={setPathname} />
    </div>
  );
}

export default App;
