import React, { useEffect, useState } from "react";
import Pi from "../../components/Pi";
import { Table } from "react-bootstrap";
import projectService from "../../services/project.service";
import timeService from "../../services/time.service";
function ProjectBreakdown(project) {
  const pid = project.project.id;
  const [timeSpent, setTimeSpent] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [labels, setLabels] = useState([]);
  const [msC, setMsC] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  useEffect(() => {
    timeService.getTimesByProject({ pid }).then((res) => {
      let msTime = {};

      res.data.forEach((t) => {
        if (t.milestone) {
          if (msTime.hasOwnProperty(t.milestone)) {
            msTime[t.milestone].hours += t.hours;
          } else {
            msTime[t.milestone] = {};
            msTime[t.milestone].hours = t.hours;
            msTime[t.milestone].user = t.user_name;
          }
        }
      });
      let toSet = [];
      Object.keys(msTime).forEach((key) => {
        toSet.push({
          milestone: key,
          hours: msTime[key].hours,
          user: msTime[key].user,
        });
      });
      toSet.sort((a, b) => {
        return a.hours - b.hours;
      });
      setTotalHours(
        toSet.reduce((a, b) => {
          return a + b.hours;
        }, 0)
      );
      setTimeSpent(toSet);
    });
    let tempContrib = project.project.users.map((u) => u.name);
    setContributors(tempContrib);

    setLabels(project.project.users.map((e) => e.name));
  }, []);
  useEffect(() => {
    projectService.getMilestones({ pid }).then((res) => {
      let obj = {};
      let completed = {};

      res.data
        .sort((a, b) => a.assigned_to - b.assigned_to)
        .map((ms) => {
          ms.users.map((u) => {
            if (obj.hasOwnProperty(u)) {
              obj[u] += 1;
            } else {
              obj[u] = 1;
            }
            if (ms.status.toLowerCase() == "complete") {
              if (completed.hasOwnProperty(ms.assigned_to)) {
                completed[u] += 1;
              } else {
                completed[u] = 1;
              }
            }
          });
        });
      let tempC = [];
      labels.map((name) => {
        if (completed[name] != undefined) {
          tempC.push({ user: name, completed: completed[name] });
        }
      });
      tempC.sort((a, b) => {
        return b.completed - a.completed;
      });
      setMsC(tempC);
    });
  }, [contributors]);

  return (
    <div className="py-5">
      <h2 className="sub-header">Milestones By Hours Spent</h2>
      <Table striped bordered responsive-md>
        <thead>
          <tr>
            <th>Milestone</th>

            <th>Hours Spent</th>
          </tr>
        </thead>
        <tbody>
          {timeSpent
            .sort((a, b) => {
              return b.hours - a.hours;
            })
            .map((msTime) => {
              return (
                <tr key={msTime.milestone}>
                  <td>{msTime.milestone}</td>

                  <td>{msTime.hours}</td>
                </tr>
              );
            })}

          <tr className="p-0 theme-bg">
            <td className="bg-dark white">
              <strong style={{ color: "white" }}>Total Time Spent</strong>
            </td>

            <td className="bg-dark white">
              <strong style={{ color: "white" }}>{totalHours}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <div className="d-flex justify-content-between">
        <div className="chart-container my-2 w-50">
          <h2 className="sub-header text-center">Top 5 Milestones By Time</h2>

          <Pi
            labels={timeSpent.map((msTime) => msTime.milestone).slice(0, 5)}
            data={timeSpent.map((msTime) => msTime.hours).slice(0, 5)}
          />
        </div>
        <div className="chart-container my-2 w-50">
          <h2 className="sub-header text-center">Overachievers</h2>
          <Pi
            labels={msC.map((c) => c.user)}
            data={msC.map((c) => c.completed)}
          />
        </div>
      </div> */}
    </div>
  );
}

export default ProjectBreakdown;
