import axios from "axios";
import authHeader from "./auth-header";
const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/"
  : "https://myomis.herokuapp.com/api/";

class NotificationService {
  poke(data) {
    return axios.post(API_URL + "notifications/create/poke", data, {
      headers: authHeader(),
    });
  }
  getBellNotifs(user) {
    return axios.post(API_URL + "notifications/get/bellnotifs", user, {
      headers: authHeader(),
    });
  }

  deleteNotification(id) {
    return axios.post(API_URL + "notifications/delete", id, {
      headers: authHeader(),
    });
  }
}
export default new NotificationService();
