import React, { useState, useEffect } from "react";
import userService from "../services/user.service";
import authService from "../services/auth.service";
import { themeList } from "../services/helper";

function ThemeWrapper() {
  const possibleThemes = themeList();
  const [tcMain, setTCMain] = useState("#342d44");
  const [tcSecondary, setTCsecondary] = useState("#342d44");
  const [tcAccent, setTCAccent] = useState("#342d44");
  useEffect(() => {
    if (authService.getCurrentUser()) {
      userService
        .getThemeColor({ uid: authService.getCurrentUser().id })
        .then((res) => {
          let themeObj = possibleThemes.find(
            (u) => u.themeDbName === res.data.theme_color
          );
          setTCMain(themeObj.themeHex);
          setTCsecondary(themeObj.themeHex);
          setTCAccent(themeObj.themeAccent);
        });
    } else {
      setTCMain("#342d44");
      setTCsecondary("#342d44");
      setTCAccent("342d44");
    }
  }, []);
  useEffect(() => {
    document.documentElement.style.setProperty("--tcMain", tcMain);
    document.documentElement.style.setProperty("--tcSecondary", tcSecondary);
    document.documentElement.style.setProperty("--tcAccent", tcAccent);
  }, [tcMain, tcAccent]);
  return <></>;
}

export default ThemeWrapper;
