import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";
import authService from "../../services/auth.service";
import Select from "react-select";
import projectService from "../../services/project.service";
import { Table } from "react-bootstrap";
import "./payment.css";
function Completed() {
  const statusOptions = [
    { label: "Warranty Charge", value: "Warranty Charge" },
    { label: "Warranty No Charge", value: "Warranty No Charge" },
    { label: "Ok to Bill", value: "Ok to Bill" },
  ];
  const user = authService.getCurrentUser();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    projectService.getCompletedProjects({ uid: user.id }).then((res) => {
      let projects = res.data.projects;
      let hours = res.data.hours;

      for (let i = 0; i < projects.length; i++) {
        projects[i].total_hours = !projects[i].total_hours
          ? 0
          : projects[i].total_hours;
        for (let j = 0; j < hours.length; j++) {
          if (projects[i].id === hours[j].project_id) {
            projects[i].total_hours += hours[j].hours;
          }
        }
      }

      setProjects(projects);
    });
  }, []);
  const handleUpdate = (i) => {
    const tempProj = [...projects];
    tempProj[i].submitted_for_billing = true;
    setProjects(tempProj);
    projectService.updateBilling({ details: projects[i] });
  };

  return (
    <div className="flex">
      <Sidebar />

      <div className="w-100 completed-projects-container">
        <header>
          <h1 className="header">Completed Projects</h1>
        </header>
        <div className="table-container">
          <Table striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Project Name</th>
                <th>Total Hours</th>
                <th>Billing Status</th>
                <th className="payment">Labor</th>
                <th className="payment">Reimbursed</th>
                <th>Submit</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => {
                return (
                  <tr key={index}>
                    <td>{project.id}</td>
                    <td>{project.project_name}</td>
                    <td>{project.total_hours}</td>
                    <td>
                      <Select
                        defaultValue={{
                          label: project.billing_status,
                          value: project.billing_status,
                        }}
                        onChange={(e) => {
                          const tempProj = [...projects];
                          tempProj[index].billing_status = e.value;
                        }}
                        options={statusOptions}
                      />
                    </td>
                    <td>
                      <input
                        value={project.labor_expense}
                        onChange={(e) => {
                          const tempProj = [...projects];
                          tempProj[index].labor_expense = parseFloat(
                            e.target.value
                          );
                          setProjects(tempProj);
                        }}
                        type="number"
                        className="w-100 fancy-input"
                      />
                    </td>
                    <td>
                      <input
                        value={project.reimbursed_expense}
                        onChange={(e) => {
                          const tempProj = [...projects];
                          tempProj[index].reimbursed_expense = parseFloat(
                            e.target.value
                          );
                          setProjects(tempProj);
                        }}
                        type="number"
                        className="w-100 fancy-input"
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => handleUpdate(index)}
                        className="edit-btn"
                      >
                        {!project.submitted_for_billing ? "submit" : "resubmit"}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Completed;
