import LoginPage from "../pages/login/LoginPage";
import Projects from "../pages/projects/Projects";
import CreateNew from "../pages/projects/CreateNew";
import View from "../pages/projects/View";
import EditExisting from "../pages/projects/EditExisting";
import TimesheetSelect from "../pages/timesheets/TimesheetSelect";
import NewSheet from "../pages/timesheets/NewSheet";
import EditSheet from "../pages/timesheets/EditSheet";
import Account from "../pages/account/Account";
import Board from "../pages/boards/Board";
import CreateRequest from "../pages/requests/CreateRequest";
import TaskOverview from "../pages/tasks/TaskOverview";
import MileStones from "../pages/projects/Milestones";
import ViewRequest from "../pages/requests/ViewRequest";
import PtoRequest from "../pages/requests/PtoRequest";
import ViewPtoRequest from "../pages/requests/ViewPtoRequest";
import Logout from "../components/Logout";
import AdminView from "../pages/timesheets/AdminView";
import Completed from "../pages/payment/Completed";
import Billing from "../pages/payment/Billing";
import UpdatePassword from "../pages/account/UpdatePassword";
import AdminSingle from "../pages/timesheets/AdminSingle";
import Directory from "../pages/account/Directory";
import DetailedView from "../pages/projects/DetailedView";
import CreateClient from "../pages/projects/CreateClient";
import HomePage from "../pages/home/HomePage";
import ClientDirectory from "../pages/projects/ClientDirectory";
import OldReports from "../pages/reports/Report";
import BPS from "../pages/reports/BPS";
import Reports from "../pages/newReports/Reports";

export const notifIconConverter = {
  poke: "hand-pointer",
  "completed milestone": "check",
};
export function sortObjArray(obj) {
  const conversionPrio = {
    low: 1,
    medium: 2,
    high: 3,
    Low: 1,
    Medium: 2,
    High: 3,
  };
  const conversionStatus = {
    "not started": 1,
    "in progress": 2,
    "client review": 3,
    "in maintenance": 4,
    complete: 5,
    "on pause": 6,
  };

  let tempArr = [...obj.array];
  let finalArr = [];
  if (obj.sortBy == "priority") {
    tempArr.sort((a, b) => {
      let prioLevelA = conversionPrio[a.priority.value.toLowerCase()];
      let prioLevelB = conversionPrio[b.priority.value.toLowerCase()];
      return obj.direction ? prioLevelA - prioLevelB : prioLevelB - prioLevelA;
    });
  } else if (obj.sortBy === "status") {
    tempArr.sort((a, b) => {
      let statusLevelA =
        obj.arrayType === "project"
          ? conversionStatus[a.status.value.toLowerCase()]
          : conversionStatus[a.status.toLowerCase()];
      let statusLevelB =
        obj.arrayType === "project"
          ? conversionStatus[b.status.value.toLowerCase()]
          : conversionStatus[b.status.toLowerCase()];
      return obj.direction
        ? statusLevelA - statusLevelB
        : statusLevelB - statusLevelA;
    });
  } else if (obj.sortBy === "alphabetical") {
    if (obj.arrayType === "milestone") {
      tempArr.sort((a, b) => {
        return obj.direction
          ? a.milestone_name
              .toLowerCase()
              .localeCompare(b.milestone_name.toLowerCase())
          : b.milestone_name
              .toLowerCase()
              .localeCompare(a.milestone_name.toLowerCase());
      });
    } else if (obj.arrayType === "project") {
      tempArr.sort((a, b) => {
        return obj.direction
          ? a.project_name
              .toLowerCase()
              .localeCompare(b.project_name.toLowerCase())
          : b.project_name
              .toLowerCase()
              .localeCompare(a.project_name.toLowerCase());
      });
    } else if (obj.arrayType === "client") {
      tempArr.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    }
  } else if (obj.sortBy === "date") {
    const distantFuture = new Date(8640000000000000);
    tempArr.sort((a, b) => {
      let dateA = a.estimated_end_date
        ? new Date(a.estimated_end_date)
        : distantFuture;

      let dateB = b.estimated_end_date
        ? new Date(b.estimated_end_date)
        : distantFuture;

      return obj.direction
        ? dateB.getTime() - dateA.getTime()
        : dateA.getTime() - dateB.getTime();
    });
  }
  finalArr = tempArr;

  return finalArr;
}
export function milestoneTemplates() {
  return {
    website: [
      "Create site on Siteground",
      "Install elementor and elementor pro",
      "Install Hello Elementor in themes",
      "Delete sample page & privacy policy",
      "Disable comments on posts",
      "Create splash page ",
      "Direct domain to splash page",
      "Change site title and tag line",
      "Upload ICO file for favicon",
      "Create Header",
      "Create Footer",
      "Add created pages to a menu",
      "Ensure Mobile Responsiveness",
      "Double Check Links",
      "Animations",
      "Implement reCAPTCHA on ALL forms",
      "Make a backup",
    ],
    headshot: [
      "Set up camera and tripod",
      "Take several pictures",
      "Choose Headshot",
      "Delete Background",
      "Add to company background",
      "Send headshot",
      "Edits",
    ],
    onboarding: [
      "New Hire Welcome Email",
      "Setup Company Email Address with New Hire Paperwork",
      "Make copies of ID(s)",
      "E-Verify",
      "Add to Microsoft Teams",
      "Outlook Calendar",
      "KISI",
      "3CX",
      "Mugshots",
      "OMiS",
      "Q&A",
      "Paychex",
    ],
  };
}
export function titleCase(string) {
  if (string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
}
export function getSelect(type) {
  if (type == "status") {
    return [
      {
        label: "Not Started",
        value: "Not Started",
      },
      {
        label: "In Progress",
        value: "In Progress",
      },
      {
        label: "Ongoing",
        value: "Ongoing",
      },
      {
        label: "Client Review",
        value: "Client Review",
      },
      { label: "Complete", value: "Complete" },
      {
        label: "On Pause",
        value: "On pause",
      },
    ];
  } else if (type == "status-nocomplete") {
    return [
      {
        label: "Not Started",
        value: "Not Started",
      },
      {
        label: "In Progress",
        value: "In Progress",
      },
      {
        label: "Ongoing",
        value: "Ongoing",
      },
      {
        label: "Client Review",
        value: "Client Review",
      },

      {
        label: "On Pause",
        value: "On pause",
      },
    ];
  } else if (type == "company") {
    return [
      { value: "Biznected", label: "Biznected" },
      { value: "Groktek", label: "Groktek" },
      { value: "Four Core", label: "4Core" },
      { value: "Adiuvo", label: "Adiuvo" },
      { value: "Proper Villains", label: "Proper Villains" },
      { value: "Spydersoft", label: "Spydersoft" },
      { value: "Slacker Comics", label: "Slacker Comics" },
      { value: "Hammer Drones", label: "Hammer Drones" },
      { value: "64 Studios", label: "64 Studios" },
    ];
  } else if (type == "templates") {
    return [
      { label: "Website", value: "website" },
      { label: "Headshot", value: "headshot" },
      { label: "Onboarding", value: "onboarding" },
    ];
  } else if (type === "priority") {
    return [
      { label: "Low", value: "Low" },
      { label: "Medium", value: "Medium" },
      { label: "High", value: "High" },
    ];
  } else if (type === "cancel") {
    return [
      {
        label: "One",
        Value: "Two ",
      },
    ];
  } else if (type === "day") {
    return [
      {
        value: "Monday",
        label: "Monday",
      },
      {
        value: "Tuesday",
        label: "Tuesday",
      },
      {
        value: "Wednesday",
        label: "Wednesday",
      },
      {
        value: "Thursday",
        label: "Thursday",
      },
      {
        value: "Friday",
        label: "Friday",
      },
      {
        value: "Saturday",
        label: "Saturday",
      },
      {
        value: "Sunday",
        label: "Sunday",
      },
      {
        label: "Volunteer",
        value: "volunteer",
      },
    ];
  }
}
export function themeList() {
  return [
    {
      themeName: "Default Grandma",
      themeHex: "#342d44",
      themeAccent: "#342d44",
      themeDbName: "default",
    },
    {
      themeName: "Cool Blue",
      themeHex: "#0a6f9b",
      themeAccent: "#0a6f9b",
      themeDbName: "theme_one",
    },
    {
      themeName: "Black and White",
      themeHex: "#1c1c1c",
      themeAccent: "#1c1c1c",
      themeDbName: "theme_two",
    },
    {
      themeName: "Evil Grandma",
      themeAccent: "#DC2027",
      themeHex: "#1c1c1c",
      themeDbName: "theme_three",
      alternateLogo: "evil",
    },
    {
      themeName: "Pea Soup",
      themeHex: "#46992e",
      themeAccent: "#46992e",
      themeDbName: "theme_four",
    },
    {
      themeName: "Gray Scale",
      themeHex: "#606060",
      themeAccent: "#606060",
      themeDbName: "theme_five",
    },
    {
      themeName: "I love gooooold",
      themeHex: "#b9a500",
      themeAccent: "#b9a500",
      themeDbName: "theme_six",
    },
    {
      themeName: "Beach Vibes",
      themeHex: "#84cbcf",
      themeAccent: "#cb432b",
      themeDbName: "theme_seven",
      alternateLogo: "beach",
    },
    {
      themeName: "Synthwave Sensations",
      themeHex:
        "linear-gradient(90deg, rgba(67,3,157,1) 0%, rgba(208,78,113,1) 44%, rgba(245,143,66,1) 75%, rgba(247,224,36,1) 100%)",
      themeAccent: "#36a7d1",
      themeDbName: "theme_eight",
    },
    {
      themeName: "Leopard Print",
      themeHex: "#a37319",
      themeAccent: "#000000",
      themeDbName: "theme_nine",
      alternateLogo: "lp",
    },
    {
      themeName: "Proper Red",
      themeHex: "#be2929",
      themeAccent: "#000000",
      themeDbName: "theme_ten",
    },
    {
      themeName: "Lavender",
      themeHex: "#c8aef5",
      themeAccent: "#c8aef5",
      themeDbName: "theme_eleven",
    },
    {
      themeName: "Mint Chip",
      themeHex: "linear-gradient(to top, #000000 -3%, #99ffcc 79%)",
      themeAccent: "#99ffcc",
      themeDbName: "theme_twelve",
      alternateLogo: "mint",
    },
    {
      themeName: "Bubblegum",
      themeHex: "linear-gradient(to bottom, #ffccff 43%, #3366ff 127%)",
      themeAccent: "#3366ff",
      themeDbName: "theme_thirteen",
    },
    {
      themeName: "Woodstock",
      themeHex: "linear-gradient(to top, #99ccff 0%, #ffcccc 90%)",
      themeAccent: "#99ccff",
      themeDbName: "theme_fourteen",
      alternateLogo: "woodstock",
    },
    {
      themeName: "Spooky",
      themeHex: "#ff9933",
      themeAccent: "#ff9933",
      themeDbName: "theme_fifteen",
      alternateLogo: "spooky",
    },
  ];
}
// routes list
export function getRoutes(options) {
  if (!options.user) {
    return [{ path: "*", element: <LoginPage /> }];
  } else {
    return [
      { path: "/", element: <HomePage /> },
      { path: "/login", element: <LoginPage /> },
      { path: "/projects", element: <Projects /> },
      { path: "/projects-create-new", element: <CreateNew /> },
      { path: "/projects-view", element: <View /> },
      { path: "/projects/edit/:id", element: <EditExisting /> },
      { path: "/time-sheets/edit/:week", element: <EditSheet /> },
      { path: "/time-sheets", element: <TimesheetSelect /> },
      { path: "/time-sheets-create-new", element: <NewSheet /> },
      { path: "/time-sheets-admin", element: <AdminView /> },
      { path: "/account", element: <Account user={options.user} /> },
      { path: "/user/:id", element: <Account user={"params"} /> },
      { path: "/boards-biznected", element: <Board company="biznected" /> },
      { path: "/boards-four-core", element: <Board company="four-core" /> },
      { path: "/boads-groktek", element: <Board company="groktek" /> },
      { path: "/boards-adiuvo", element: <Board company="adiuvo" /> },
      { path: "/boards-spydersoft", element: <Board company="spydersoft" /> },
      { path: "/requests", element: <CreateRequest /> },
      { path: "/requests-view-all", element: <ViewRequest /> },
      { path: "/request-pto", element: <PtoRequest /> },
      { path: "/view-pto-request", element: <ViewPtoRequest /> },
      { path: "/tasks", element: <TaskOverview /> },
      { path: "/projects/miestone/:id", element: <MileStones /> },
      { path: "/logout", element: <Logout /> },
      { path: "/completed-projects", element: <Completed /> },
      { path: "/billing", element: <Billing /> },
      { path: "/update-password", element: <UpdatePassword /> },
      {
        path: "/time-sheets-admin-single/:start/:end/:user",
        element: <AdminSingle />,
      },
      { path: "/create-client", element: <CreateClient /> },
      { path: "/client-directory", element: <ClientDirectory /> },
      { path: "/directory", element: <Directory /> },
      { path: "/project-details/:id", element: <DetailedView /> },
      { path: "/reports", element: <OldReports /> },
      { path: "/reports/BPS", element: <BPS /> },
      { path: "/new-reports", element: <Reports /> },
      // { path: "/loginpopup", element: <LoginPopup /> },
    ];
  }
}
