import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import User from "../../components/User";
import userService from "../../services/user.service";
import villain from "../../avatars/villain.png";
import coffee from "../../avatars/coffee.png";
import cupcake from "../../avatars/cupcake.png";
import lemon from "../../avatars/lemon.png";
import penguin from "../../avatars/penguin.png";
import flower from "../../avatars/flower.png";
import sun from "../../avatars/sun.png";
import moon from "../../avatars/moon.png";

function Directory() {
  let possibleImages = {
    villain: villain,
    coffee: coffee,
    flower: flower,
    penguin: penguin,
    lemon: lemon,
    cupcake: cupcake,
    sun: sun,
    moon: moon,
  };
  const [users, setUsers] = useState([]);

  useEffect(() => {
    userService.getAllUsers({ needID: true, needAvatar: true }).then((res) => {
      res.data.sort((a) => a.userName);
      setUsers(res.data);
    });
  }, []);
  const handleRoleChange = (e, id) => {
    let newList = [...users];
    let formatted = e.map((t) => t.value);
    newList.forEach((user) => {
      return user.userID == id ? (user.roles = formatted) : null;
    });

    setUsers(newList);
  };

  return (
    <div className="flex w-100">
      <Sidebar />
      <div className="w-100 inner-container">
        <header className="header">User Directory</header>
        <div className="user-card-container">
          {users.map((user) => {
            return user.userName != "Admin" ? (
              <div key={user.userID}>
                <User
                  background={user.color}
                  id={user.userID}
                  name={user.userName}
                  image={possibleImages[user.avatar]}
                  roles={user.roles}
                  handleRoleChange={handleRoleChange}
                />
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Directory;
