import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import authService from "../../services/auth.service";
import requestService from "../../services/request.service";
import Sidebar from "../../components/Sidebar";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const FadeIn = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;

function PtoRequest() {
  let userId = authService.getCurrentUser().id;
  let role = authService.getCurrentUser().roles;
  const [pto, setPto] = useState();
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    userService.getPto(userId).then((res) => {
      setPto(res.data.pto);
    });
  }, [userId]);

  function validate() {
    const num = parseFloat(amount);
    console.log("num: " + num);

    if (
      num <= 0 ||
      typeof num !== "number" ||
      num % 0.25 !== 0 ||
      amount > pto
    ) {
      return false;
    }
    if (!reason) {
      return false;
    } else return true;
  }

  function handleSubmit() {
    if (validate()) {
      requestService.requestPto(amount, reason, userId).then((res) => {
        console.log(res.data);
      });
    } else {
      setStatus("Invalid: Make sure everything is correct");
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="request-container">
        <div className="flex align-items-center">
          <header className="header">Request Sick Leave</header>
          {role.includes("ROLE_MODERATOR") || role.includes("ROLE_ADMIN") ? (
            <button
              onClick={() => navigate("/view-pto-request")}
              className="save-ms-btn purp-btn"
            >
              View Requests
            </button>
          ) : null}
        </div>
        <FadeIn>
          <div className="request-input-container">
            <h2>{`Available Time: ${pto}`}</h2>
            <div className="form-container">
              {status ? <h5 className="text-danger">{status}</h5> : null}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="ts-label">Amount</label>
                  <input
                    type="number"
                    className="fancy-input"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <label className="ts-label">Reason</label>
                  <textarea
                    className="req-ta w-100"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  ></textarea>
                </div>
                <div className="submit-group">
                  <button type="submit" className="edit-btn">
                    Submit Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default PtoRequest;
