import React, { useEffect, useState } from "react";
import projectService from "../../services/project.service";
import { Table } from "react-bootstrap";
function CompletedMilestones(props) {
  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    projectService.getMilestones({ pid: props.id }).then((res) => {
      let temp = res.data;
      temp.forEach((ms) => {
        let at = ms.users.map((u) => {
          return { label: u.name || "", value: u.name || "" };
        });
        ms.assigned_to = at;
      });

      setMilestones(temp);
    });
  }, []);

  const revertMilestone = (msId) => {
    projectService
      .updateMs({ msId: [msId], field: "status", newValue: "Not Started" })
      .then(() => {
        console.log("reverted");
      });
  };

  return (
    <>
      <header className="my-5 sub-header">Completed Milestones</header>
      <Table className="milestone-table">
        <thead>
          <tr>
            <th className="completed-ms-head">Milestone</th>
            <th>Completed By</th>
            <th>Revert</th>.
          </tr>
        </thead>
        <tbody>
          {milestones.map((u, i) => {
            if (u.status === "Complete")
              return (
                <tr key={u.id}>
                  <td>{u.milestone_name}</td>
                  <td>
                    {u.assigned_to.map((at, index) => {
                      if (index < u.assigned_to.length - 1) {
                        return at.value + ", ";
                      } else {
                        return at.value;
                      }
                    })}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        let newArr = [...milestones];
                        newArr[i].status = {
                          label: "Not Started",
                          value: "Not Started",
                        };
                        setMilestones(newArr);
                        revertMilestone(u.id);
                      }}
                      className="edit-btn"
                    >
                      revert
                    </button>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default CompletedMilestones;
