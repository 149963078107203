import React from "react";
import "../pages/reports/report.css";
import styled, { keyframes } from "styled-components";
// import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fadeInUp } from "react-animations";

const FadeUp = styled.div`
  animation: 0.5s ${keyframes`${fadeInUp}`};
`;

function ReportsOverview() {
  const navigate = useNavigate();
  const handleRoute = (path) => {
    navigate(path);
  };

  return (
    <FadeUp>
      <div className="p-decision view-projects-container">
        <h1 className="card-title boxed">BPS Reports</h1>
        <button
          onClick={() => handleRoute("/reports/BPS")}
          className="view-proj-btn"
          style={{
            backgroundColor: "white",
            border: "none",
            color: "black",
            fontSize: "1.2rem",
          }}
        >
          View Report
        </button>
      </div>
    </FadeUp>
  );
}

export default ReportsOverview;
