import React from "react";
import "./projects.css";
import Sidebar from "../../components/Sidebar";
import ProjectSelect from "../../components/ProjectSelect";

function Projects() {
  return (
    <div className="flex">
      <Sidebar />

      <ProjectSelect />
    </div>
  );
}

export default Projects;
