import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import projectService from "../../services/project.service";
import authService from "../../services/auth.service";
import Sidebar from "../../components/Sidebar";
import ProjectViewInput from "../../components/ProjectViewInput";
import requestService from "../../services/request.service";
import Select from "react-select";
import _ from "lodash";
import Loading from "../../components/Loading";
import userService from "../../services/user.service";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import Th from "../../components/Th";
import { sortObjArray, getSelect } from "../../services/helper";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function View() {
  const navigate = useNavigate();
  const adminView = parseInt(localStorage.getItem("adminView")) || null;
  let userId = authService.getCurrentUser().id;
  const roles = authService.getCurrentUser().roles;
  const [projects, setProjects] = useState([]);
  const [staticProjects, setStaticProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [statusSort, setStatusSort] = useState("in progress");
  const [alphUp, setAlphUp] = useState(false);
  const [prioUp, setPrioUp] = useState(false);
  const [statusUp, setStatusUp] = useState(false);
  const [dateUp, setDateUp] = useState(false);
  const [unreadProjectRequests, setUnreadProjectRequests] = useState(0);
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };

  useEffect(() => {
    if (authService.getCurrentUser().roles.includes("ROLE_ADMIN")) {
      console.log("User is an admin, going to project service");
      projectService.getRequestedCount().then((res) => {
        console.log("Requested " + JSON.stringify(res));
        setUnreadProjectRequests(res.data.reqCount);
      });
    }
  }, []);

  useEffect(() => {
    userId = authService.getCurrentUser().id;
    console.log(userId + " and " + adminView);
    projectService.getProjects({ id: userId, adminView }).then((res) => {
      let newList = [...res.data];
      newList = _.groupBy(newList, (proj) => proj.status);
      let temp = [];
      for (let i in newList) {
        temp.push(newList[i]);
      }
      let newListFinal = flatten([...temp]);
      setProjects(newListFinal);
      setStaticProjects(newListFinal);
      setLoading(false);
      userService.getAllUsers().then((res) => {
        let tempArr = [];
        res.data.forEach((user) => {
          tempArr.push({ value: user.userName, label: user.userName });
        });
        setUsers(tempArr);
      });
    });
  }, []);

  useEffect(() => {
    if (search.length != 0) {
      if (statusSort != "all") {
        setStatusSort("all");
      }
      let tempArr = [...staticProjects];
      tempArr = tempArr.filter((obj) => {
        return JSON.stringify(obj).toLowerCase().includes(search.toLowerCase());
      });

      setProjects(tempArr);
    } else {
      setProjects(staticProjects);
    }
  }, [search]);
  const getProjectsByName = (e) => {
    setUser(e.value);
  };
  useEffect(() => {
    if (user) {
      projectService.byUsername({ name: user, uid: userId }).then((res) => {
        let newList = [...res.data];
        newList = _.groupBy(newList, (proj) => proj.status);
        let temp = [];
        for (let i in newList) {
          temp.push(newList[i]);
        }
        let newListFinal = flatten([...temp]);

        setProjects(newListFinal);
      });
    }
  }, [user]);

  const handleEdit = (path) => {
    window.open(`#/project-details/${path}`);
  };
  const handleSort = (which) => {
    if (which == "alphabetical") {
      setProjects(
        sortObjArray({
          array: projects,
          sortBy: "alphabetical",
          direction: alphUp,
          arrayType: "project",
        })
      );
      setAlphUp(!alphUp);
    } else if (which === "status") {
      setProjects(
        sortObjArray({
          array: projects,
          sortBy: "status",
          direction: statusUp,
        })
      );
      setStatusUp(!statusUp);
    } else if (which === "priority") {
      setProjects(
        sortObjArray({
          array: projects,
          sortBy: "priority",
          direction: prioUp,
        })
      );
      setPrioUp(!prioUp);
    } else if (which === "date") {
      setProjects(
        sortObjArray({
          array: projects,
          sortBy: "date",
          direction: dateUp,
        })
      );
      setDateUp(!dateUp);
    }
  };

  // flatten array by noah freitas on stack overflow
  const flatten = (arr) => {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="content-container">
        <h1 className="header">Your Projects</h1>
        <div className="controls-container">
          <div className="search-container">
            <p className="group-header">Search</p>
            <ProjectViewInput searchProject={setSearch} />

            {roles.includes("ROLE_ADMIN") ? (
              <div>
                <p className="mt-2 text-right group-header"> Filter User</p>
                <Select
                  styles={customStyles}
                  onChange={getProjectsByName}
                  options={users}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Fade>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div className=" tab-switcher-container d-flex justify-content-center">
                <div className="tab-switcher-head">
                  <div
                    onClick={() => setStatusSort("in progress")}
                    className={`${
                      statusSort == "in progress" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    In Progress
                  </div>
                  <div
                    className={`${
                      statusSort == "not started" ? "active-tab" : null
                    } tab-head-item`}
                    onClick={() => setStatusSort("not started")}
                  >
                    Not Started
                  </div>
                  <div
                    onClick={() => setStatusSort("ongoing")}
                    className={`${
                      statusSort == "ongoing" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    Ongoing
                  </div>
                  <div
                    onClick={() => setStatusSort("client review")}
                    className={`${
                      statusSort == "client review" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    Client Review
                  </div>
                  <div
                    onClick={() => setStatusSort("on pause")}
                    className={`${
                      statusSort == "on pause" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    On Pause
                  </div>
                  <div
                    onClick={() => setStatusSort("complete")}
                    className={`${
                      statusSort == "complete" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    Complete
                  </div>
                  <div
                    onClick={() => setStatusSort("all")}
                    className={`${
                      statusSort == "all" ? "active-tab" : null
                    } tab-head-item`}
                  >
                    All
                  </div>
                  {roles.includes("ROLE_ADMIN") ? (
                    <div
                      onClick={() => setStatusSort("requested")}
                      className={`${
                        statusSort == "requested" ? "active-tab" : null
                      } tab-head-item`}
                    >
                      <span>Requested</span>
                      {unreadProjectRequests > 0 && (
                        <span className="badge">{unreadProjectRequests}</span>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <Table
                responsive="md sm"
                bordered
                hover
                className="desktop-projects-view "
              >
                <thead className="view-thead">
                  <tr>
                    <Th
                      handleSorter={() => {
                        handleSort("alphabetical");
                      }}
                      name="Project Name"
                    />

                    <Th
                      handleSorter={() => {
                        handleSort("date");
                      }}
                      name="Due"
                    />

                    <th style={{ width: "9%" }}>Milestones</th>
                  </tr>
                </thead>
                <tbody>
                  {projects
                    .sort((a, b) => a.status)
                    .map((project, i) => {
                      if (project.status == null) return null;
                      if (
                        project.status.toLowerCase() === statusSort ||
                        (statusSort === "all" &&
                          project.status.toLowerCase() != "requested")
                      ) {
                        return (
                          <tr key={i}>
                            <td
                              className="pointer"
                              onClick={() => handleEdit(project.id)}
                            >
                              {project.project_name}
                            </td>
                            <td>
                              {project.estimated_end_date || "no date set"}
                            </td>

                            <td align="center">
                              <button
                                className="edit-btn"
                                onClick={() =>
                                  window.open(`#/project-details/${project.id}`)
                                }
                              >
                                {project.milestone_count
                                  ? `${project.milestone_complete} / ${project.milestone_count}`
                                  : "view"}
                              </button>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </Table>
            </div>
          )}
        </Fade>
      </div>
    </div>
  );
}

export default View;
