import React from "react";
import "./loginpage.css";
import Login from "../../components/Login";
import blobscene from "./blob-scene-haikei.svg";

function Home() {
  return (
    <div style={{ backgroundImage: `url(${blobscene})` }} className="page-home">
      <Login />
      <h1 id="omis">OMiS</h1>
    </div>
  );
}

export default Home;
