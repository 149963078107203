import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import timeService from "../../services/time.service";
import authService from "../../services/auth.service";
import ExcelExporter, { ExportCSV } from "../../services/ExcelExporter";
import userService from "../../services/user.service";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import AdminSingle from "./AdminSingle";

function AdminView() {
  const navigate = useNavigate();
  const user = authService.getCurrentUser();
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [singleUser, setSingleUser] = useState("");
  const [singleView, setSingleView] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [hours, setHours] = useState({});
  let [arrHours, setArrHours] = useState([]);
  const [totalHours, setTotalHours] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const internList = ["Benji Lucas", "Alex Soto"];
  function handleAdminSingle(data) {
    // navigate(
    //   `/time-sheets-admin-single/${data.startDate}/${data.endDate}/${data.user}`
    // );
    if (data) {
      setSingleUser(data.user);
      setStart(data.startDate);
      setEnd(data.endDate);
      setSingleView(true);
    } else {
      setSingleUser("");
      setStart("");
      setEnd("");
      setSingleView(false);
    }
  }
  function nthWord(str, n) {
    var m = str.match(new RegExp("^(?:\\w+\\W+){" + --n + "}(\\w+)"));

    return m && m[1];
  }
  function lookUpWeek(start, end) {
    start = start.split("/").join("-");
    end = end.split("/").join("-");
    setStartDate(start);
    setEndDate(end);
    timeService
      .getAllByWeek({ startDate: start, endDate: end })
      .then(function (res) {
        console.log("RES DATA");
        console.log(res.data);
        setHours(res.data);
        let temp = [];
        Object.keys(res.data).forEach((key) => {
          let bizHours = res.data[key].Biznected || 0;
          let grokHours = res.data[key].Groktek || 0;
          let adiuvoHours = res.data[key].Adiuvo || 0;
          let fourCoreHours = res.data[key]["Four Core"] || 0;
          let spydersoftHours = res.data[key].Spydersoft || 0;
          let properVillainsHours = res.data[key]["Proper Villains"] || 0;
          let slackerHours = res.data[key]["Slacker Comics"] || 0;

          let totalHours =
            spydersoftHours +
            bizHours +
            grokHours +
            adiuvoHours +
            fourCoreHours +
            properVillainsHours +
            slackerHours;
          let user = key === "Gael Ballesteros" ? "Gael Guerrero" : key;

          temp.push({
            user: user,
            PV: properVillainsHours,
            FourCore: fourCoreHours,
            Spydersoft: spydersoftHours,
            Biz: bizHours,
            Groktek: grokHours,
            Adiuvo: adiuvoHours,
            Slacker: slackerHours,
            Total: totalHours,
          });
        });
        temp.sort((a, b) => {
          return nthWord(a.user, 2) > nthWord(b.user, 2)
            ? 1
            : nthWord(a.user, 2) < nthWord(b.user, 2)
            ? -1
            : 0;
        });
        temp = temp.filter((notIntern) => !internList.includes(notIntern.user));
        setArrHours(temp);
      });
  }

  function getTotalUserHours() {}
  getTotalUserHours();
  useEffect(() => {
    timeService
      .getWeekEndings({ id: user.id, name: user.name })
      .then(function (res) {
        let total = [];
        res.data.map((e) => {
          let tempObj = {};
          tempObj.label = e.week_ending;
          tempObj.value = e.week_ending;
          total.push(tempObj);
        });
        setWeeks(total);
      });
    userService.getTotalHours({ all: true }).then(function (res) {
      console.log(res.data);
      setTotalHours(res.data);
    });
  }, []);
  function handleSelect(ranges) {
    let { startDate, endDate } = ranges.selection;
    startDate = new Date(startDate).toLocaleDateString("fr-CA");
    endDate = new Date(endDate).toLocaleDateString("en-ZA");
    console.log("DATES: ", startDate, endDate);

    lookUpWeek(startDate, endDate);
  }

  const addToPto = () => {
    console.log(hours);
    timeService.addToPto(hours).then((res) => {
      console.log(res);
      if (res.data === "SUCCESS") {
        console.log("YAYAYAYY");
        timeService.setAdded(startDate, endDate).then(() => {});
      }
    });
  };

  return (
    <div className="flex">
      <Sidebar />
      {singleView ? (
        <AdminSingle
          handleBack={() => handleAdminSingle(false)}
          start={start}
          end={end}
          user={singleUser}
        />
      ) : (
        <div className="timesheet-overview">
          <div className="ts-head">
            <header className="header">Admin View</header>
            <div className="mt-5 mb-5 w-50 select-week-container">
              <p className="sub-header">Hours By Week Ending</p>
              <p>Start date: {startDate}</p>
              <p>End Date: {endDate}</p>
              <DateRangePicker
                months={2}
                direction="horizontal"
                isOutsideRange={() => false}
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                ranges={[selectionRange]}
                onChange={handleSelect}
              />
            </div>
            {startDate && endDate ? (
              <>
                <ExportCSV
                  csvData={arrHours}
                  fileName={`timesheet_report_${startDate}-${endDate}`}
                />
                <button className="purp-btn" onClick={addToPto}>
                  Add To PTO/Sick Leave
                </button>
              </>
            ) : null}
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Spydersoft</th>
                  <th>Biz</th>
                  <th>Groktek</th>
                  <th>Adiuvo</th>
                  <th>4Core</th>
                  <th>Slacker</th>
                  <th>PV Hours</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(hours)
                  .map((e) => (e === "Gael Ballesteros" ? "Gael Guerrero" : e))
                  .sort((a, b) => {
                    return nthWord(a, 2) > nthWord(b, 2)
                      ? 1
                      : nthWord(a, 2) < nthWord(b, 2)
                      ? -1
                      : 0;
                  })
                  .map((entry, i) => {
                    let entryFr =
                      entry === "Gael Guerrero" ? "Gael Ballesteros" : entry;
                    let bizHours = hours[entryFr].Biznected || 0;
                    let grokHours = hours[entryFr].Groktek || 0;
                    let adiuvoHours = hours[entryFr].Adiuvo || 0;
                    let fourCoreHours = hours[entryFr]["Four Core"] || 0;
                    let spydersoftHours = hours[entryFr].Spydersoft || 0;
                    let slackerHours = hours[entryFr]["Slacker Comics"] || 0;
                    let properVillainsHours =
                      hours[entryFr]["Proper Villains"] || 0;
                    let totalHours =
                      spydersoftHours +
                      bizHours +
                      slackerHours +
                      grokHours +
                      adiuvoHours +
                      fourCoreHours +
                      properVillainsHours;
                    return (
                      <tr
                        className="pointer hover-purp"
                        onClick={() =>
                          handleAdminSingle({ user: entry, startDate, endDate })
                        }
                        key={i}
                      >
                        <td>{entry}</td>
                        <td>{spydersoftHours}</td>
                        <td>{bizHours}</td>
                        <td>{grokHours}</td>
                        <td>{adiuvoHours}</td>
                        <td>{fourCoreHours}</td>
                        <td>{slackerHours}</td>
                        <td>{properVillainsHours}</td>
                        <td>{totalHours}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="spacer"></div>
            <hr />
            <p className="sub-header">Users Total Hours</p>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Total Hours</th>
                  <th>Available PTO</th>
                </tr>
              </thead>
              <tbody>
                {totalHours.map((user) => {
                  return (
                    <tr>
                      <td>{user.user_name}</td>
                      <td>{user.hours.toFixed(2)}</td>
                      <td>{user.pto}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminView;
