import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Sidebar from "../../components/Sidebar";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { getSelect, titleCase } from "../../services/helper";
import authService from "../../services/auth.service";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function CreateNew() {
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const navigate = useNavigate();
  const roles = authService.getCurrentUser().roles;
  const selectCompanies = getSelect("company");
  const [recurring, setRecurring] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [client_name, setClientName] = useState("");
  const [client_phone, setClientPhone] = useState("");
  const [client_email, setClientEmail] = useState("");
  const [client_address, setClientAddress] = useState("");
  const [contributors, setContributors] = useState([]);
  const [company, setCompany] = useState("biznected");
  let [equipment, setEquipment] = useState([
    { name: "", qty: "", description: "" },
  ]);
  const submitNewProject = (data) => {

    if (authService.getCurrentUser().roles.includes("ROLE_USER")) {
      data.status = "Requested";
    }

    projectService
      .submitProject(data)
      .then(() => {
        navigate("/projects");
      })
      .catch((err) => {});
  };
  useEffect(() => {
    userService.getAllUsers().then(function (res) {
      let tempArr = [];
      let tempContrib = [];
      res.data.map((user) => {
        let selectObj = {
          label: titleCase(user.userName),
          value: user.userName.toLowerCase(),
          roles: user.roles,
        };
        tempArr.push(selectObj);
      });
      setUsers(tempArr);
      projectService.getClients().then((res) => {
        let temp = [];
        res.data.forEach((c) => {
          temp.push({
            label: `${c.name} - ${c.company}`,
            value: c.name,
            name: c.name,
            email: c.email,
            address: c.address,
            phone: c.phone,
          });
        });
        setClientOptions(temp);
      });
    });
  }, []);
  useEffect(() => {
    if (users.length > 0) {
      let tempContrib = [...users];

      tempContrib = tempContrib.filter((c) => {
        return c.roles.some((r) => r.name == company);
      });
      setContributors(tempContrib);
    }
  }, [company]);
  const initialValues = {
    // start general
    internal: false,
    project_name: "",
    start_date: "",
    estimated_end_date: "",
    tasks: [],
    description: "",
    additional_notes: "",
    system: "",
    version: "",
  };
  const handleCompany = (e) => {
    setCompany(e.value);
  };
  const handleContributors = (e) => {
    setContributors(e);
  };
  const clientLoader = (e) => {
    setClientName(e.name);
    setClientEmail(e.email);
    setClientAddress(e.address);
    setClientPhone(e.phone);
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setError("");
      let tempArr = values;
      if (equipment[0].name.length > 0) {
        tempArr.equipment = equipment;
      }
      tempArr.company = company;
      tempArr.contributors = contributors;
      tempArr.client_name = client_name;
      tempArr.client_address = client_address;
      tempArr.client_email = client_email;
      tempArr.client_phone = client_phone;
      tempArr.contributors.length > 0
        ? submitNewProject(tempArr)
        : setError("Must select at least one contributor");
    },
  });
  return (
    <div className="create-new-container">
      <Sidebar />
      <div className="break form-container">
        <h1 className="header">New Project</h1>
        {error ? <div className="proj-error">{error}</div> : null}
        <Fade>
          <form className="add-new-form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label>
                Select Company <span className="required">*</span>
              </label>
              <Select
                aria-label="company-select"
                styles={customStyles}
                onChange={handleCompany}
                options={selectCompanies}
              />
            </div>
            <div className="form-group">
              <label>
                Select Contributors <span className="required">*</span>
              </label>
              <Select
                styles={customStyles}
                onChange={handleContributors}
                isMulti={true}
                options={users}
                value={contributors}
              />
            </div>

            <div className="form-group">
              <label>
                Project Name <span className="required">*</span>
              </label>
              <div className="justify-content-between w-100 flex">
                <input
                  className="p-name-input fancy-input"
                  onChange={formik.handleChange}
                  placeholder="example project"
                  id="project_name"
                  name="project_name"
                  type="text"
                ></input>
              </div>
              <label className="mt-2">Internal Project?</label>
              <div className="align-items-start d-flex flex-column">
                <input
                  className="fancy-input"
                  name="internal"
                  onChange={formik.handleChange}
                  type="checkbox"
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label>Load Existing Client</label>
              <Select
                onChange={clientLoader}
                styles={customStyles}
                options={clientOptions}
              />
            </div>
            <div className="form-group">
              <label>
                Contact Name <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={(e) => setClientName(e.target.value)}
                placeholder="first and last name"
                id="contact-name"
                value={client_name}
                type="text"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Phone</label>
              <input
                className="fancy-input"
                onChange={(e) => setClientPhone(e.target.value)}
                type="tel"
                id="contact-phone"
                value={client_phone}
                maxLength={14}
                placeholder="(111) 111-1111"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Email</label>
              <input
                className="fancy-input"
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="example@email.com"
                value={client_email}
                type="email"
                id="contact-email"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Address</label>
              <input
                className="fancy-input"
                onChange={(e) => setClientAddress(e.target.value)}
                placeholder="1234 example blvd"
                value={client_address}
                type="text"
                id="contact-address"
              ></input>
            </div>
            <div className="form-group">
              <label>
                Start Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                id="start_date"
                name="start_date"
                type="date"
              ></input>
            </div>
            <div className="form-group align-items-start">
              <label>
                Estimated Due Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                id="estimated_end_date"
                name="estimated_end_date"
                type="date"
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                onChange={formik.handleChange}
                rows="3"
                id="description"
                name="description"
              ></textarea>
            </div>
            <div className="form-group">
              <label>Additional Notes</label>
              <textarea
                onChange={formik.handleChange}
                rows="3"
                id="additional_notes"
                name="additional_notes"
              ></textarea>
            </div>
            <div className="form-group equipment-group">
              {equipment.map((item, index) => {
                return (
                  <div key={index} className="w-100">
                    <div className="equipment-top w-40">
                      <input
                        className="fancy-input equipment-name"
                        type="text"
                        placeholder="Name"
                        value={equipment[index].name}
                        onChange={(e) => {
                          equipment[index].name = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                      <input
                        className="fancy-input equipment-qty"
                        placeholder="Qty"
                        type="number"
                        min={0}
                        value={equipment[index].qty}
                        onChange={(e) => {
                          equipment[index].qty = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                    </div>
                    <div>
                      <textarea
                        className="equipment-description"
                        placeholder="Optional desctiption..."
                        value={equipment[index].description}
                        onChange={(e) => {
                          equipment[index].description = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              <button
                className="add-equipment-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setEquipment([
                    ...equipment,
                    { name: "", qty: "", description: "" },
                  ]);
                }}
              >
                +
              </button>
            </div>

            <div className="form-group submit-group">
              <button type="submit">
                {roles.includes("ROLE_ADMIN") ? "submit" : "request"}
              </button>
            </div>
          </form>
        </Fade>
      </div>
    </div>
  );
}

export default CreateNew;
