import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import projectService from "../services/project.service";
import authService from "../services/auth.service";

function UpcomingProjectModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [projects, setProjects] = useState([]);
  const [holdProjName, setHoldProjName] = useState("");
  const [holdProjID, setHoldProjID] = useState("");
  const [milestones, setMilestones] = useState([]);
  const user = authService.getCurrentUser();
  const [addProject, setAddProject] = useState({});

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "var(--tcMain)" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };

  const handleProjectChange = (e) => {
    setHoldProjID(e.value);
    setHoldProjName(e.label);
    let tempMsArr = [];
    projectService.getMilestones({ pid: e.value }).then((res) => {
      res.data.forEach((ms) => {
        if (ms.company && ms.status != "Complete") {
          tempMsArr.push({
            label: ms.milestone_name,
            value: ms.milestone_name,
            id: ms.id,
            company: ms.company,
          });
        }
      });
      setMilestones(tempMsArr);
    });
  };

  useEffect(() => {
    projectService.getProjects({ id: user.id }).then((res) => {
      let data = res.data;
      let finalArr = [];
      data.map((item) => {
        if (item.status != "completed") {
          finalArr.push({
            label: item.project_name,
            value: item.id,
            company: item.company,
            milestones: item.milestones,
          });
        }
      });
      setProjects(finalArr);
    });
  }, []);

  useEffect(() => {
    setAddProject({ name: holdProjName, id: holdProjID });
  }, [holdProjID, holdProjName]);

  const handleAddProject = () => {
    props.setUpcomingProjects([...props.upcomingProjects, addProject]);

    handleClose();
  };

  return (
    <div>
      <Button variant="dark" onClick={handleShow}>
        Add Project
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Project.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Projects</Form.Label>
              <Select
                onChange={(e) => handleProjectChange(e)}
                value={{ label: holdProjName, value: holdProjName }}
                styles={customStyles}
                className="new-entry-select"
                options={projects}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="dark" onClick={handleAddProject}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpcomingProjectModal;
