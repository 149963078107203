import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { getRoutes } from "./helper";
import authService from "./auth.service";

function CustomRouter(props) {
  let user = authService.getCurrentUser() || null;
  const routeList = getRoutes({ user: authService.getCurrentUser() || null });
  const location = useLocation();
  useEffect(() => {
    props.setPathname(location.pathname);
  }, [location]);

  return (
    <>
      <Routes>
        {routeList.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}
      </Routes>
    </>
  );
}

export default CustomRouter;
