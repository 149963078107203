import React, { useState } from "react";
import { Alert } from "react-bootstrap";

function TextInput(props) {
  const [msInput, setmsInput] = useState("");
  const [msDate, setMsDate] = useState("");
  return (
    <>
      <div className="d-flex">
        <input
          value={msInput}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.addMilestone(msInput, msDate);
              if (msDate && msInput) {
                setmsInput("");
                setMsDate("");
              }
            }
          }}
          onChange={(e) => setmsInput(e.target.value)}
          className="w-75 fancy-input"
          placeholder="milestone name"
        ></input>
        <div>
          <input
            value={msDate}
            onChange={(e) => setMsDate(e.target.value)}
            className=" mx-2 fancy-input"
            type="date"
          ></input>
        </div>
      </div>
      <button
        onClick={() => {
          props.addMilestone(msInput, msDate);
          if (msDate && msInput) {
            setmsInput("");
            setMsDate("");
          }
        }}
        className="mt-2 ms-btn purp-btn"
      >
        create
      </button>
    </>
  );
}

export default TextInput;
