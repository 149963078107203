import React, { useState } from "react";
import authHeader from "../../services/auth-header";
import authService from "../../services/auth.service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function UpdatePassword() {
  const navigate = useNavigate();
  const [updatedPass, setUpdatedPass] = useState("");
  const user = authService.getCurrentUser();
  const handleSubmit = () => {
    authService
      .updatePassword({ uid: user.id, newPass: updatedPass })
      .then(() => {
        console.log("updated");
        navigate("/logout");
      });
  };
  return (
    <div className="d-flex flex-column">
      <input
        type="password"
        onChange={(e) => setUpdatedPass(e.target.value)}
        className="w-50 fancy-input"
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            handleSubmit();
          }
        }}
      />
      <button onClick={handleSubmit} className="purp-btn">
        update
      </button>
    </div>
  );
}

export default UpdatePassword;
