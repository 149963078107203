import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : window.location.href.includes("proper")
  ? "https://omis.herokuapp.com/api/"
  : window.location.href.includes("omisbk")
  ? "https://myomis-archive.herokuapp.com/api/"
  : "https://myomis.herokuapp.com/api/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
  getAllUsers(params) {
    return axios.post(API_URL + "users/all", params, { headers: authHeader() });
  }
  getUserProfile(user) {
    return axios.post(API_URL + "user/profile", user, {
      headers: authHeader(),
    });
  }
  updateUserProfile(user) {
    return axios.post(API_URL + "user/update-profile", user, {
      headers: authHeader(),
    });
  }
  getTotalHours(user) {
    return axios.post(API_URL + "user/get-hours", user, {
      headers: authHeader(),
    });
  }
  getNotifications(user) {
    return axios.post(API_URL + "user/notifications", user, {
      headers: authHeader(),
    });
  }
  getThemeColor(user) {
    return axios.post(API_URL + "user/theme-color", user, {
      headers: authHeader(),
    });
  }
  checkClocked(user) {
    return axios.post(API_URL + "user/is-clocked", user, {
      headers: authHeader(),
    });
  }
  changeRoles(data) {
    return axios.post(API_URL + "user/change-role", data, {
      headers: authHeader(),
    });
  }

  getPto(id) {
    return axios.get(API_URL + `user/get-pto/${id}`, { headers: authHeader() });
  }
}

export default new UserService();
