import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import projectService from "../../services/project.service";
import { useNavigate, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function CreateClient() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const handleChange = (e) => {
    let type = e.target.name;
    let value = e.target.value;
    switch (type) {
      case "name":
        setName(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "company":
        setCompany(value);
        break;
      default:
    }
  };
  const handleSubmit = () => {
    const payload = { name, address, phone, email, company };
    projectService.newClient(payload).then(() => {
      setName("");
      setAddress("");
      setPhone("");
      setEmail("");
      setCompany("");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  };
  return (
    <div className="flex">
      <Sidebar />
      <div className="center-container inner-container">
        <div className=" new-client-container">
          {success ? (
            <Fade>
              <div className="alert alert-success" role="alert">
                <p>Client succesfully created!</p>
                <Link
                  className="hover theme-color bold"
                  to={"/client-directory"}
                >
                  view clients
                </Link>
              </div>
            </Fade>
          ) : null}
          <h2 className="subheader text-center">Client Information</h2>
          <div className="my-3 input-group">
            <label className="nc-label">Contact Name</label>
            <input
              onChange={handleChange}
              name="name"
              value={name}
              placeholder="Contact Name"
              className="fancy-input"
            ></input>
          </div>
          <div className="my-3 input-group">
            <label className="nc-label">Company</label>
            <input
              onChange={handleChange}
              name="company"
              value={company}
              placeholder="Company Name"
              className="fancy-input"
            ></input>
          </div>
          <div className="my-3 input-group">
            <label className="nc-label">Client Phone</label>
            <input
              onChange={handleChange}
              name="phone"
              placeholder="(xxx) xxx xxxx"
              type="tel"
              value={phone}
              className="fancy-input"
            ></input>
          </div>
          <div className="my-3 input-group">
            <label className="nc-label">Client email</label>
            <input
              onChange={handleChange}
              placeholder="example@email.com"
              type="email"
              name="email"
              value={email}
              className="fancy-input"
            ></input>
          </div>
          <div className="my-3 input-group">
            <label className="nc-label">Client Address</label>
            <input
              onChange={handleChange}
              placeholder="1234 Example St"
              name="address"
              value={address}
              className="fancy-input"
            ></input>
          </div>
          <div className="mt-5 d-flex align-items-center justify-content-between">
            <button onClick={handleSubmit} className="m-0 purp-btn">
              Create
            </button>
            <Link className="theme-color bold" to={"/client-directory"}>
              <p className="text-decoration-underline">Directory</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
