import React from "react";
import ReportsOverview from "../../components/ReportsOverview";
import Sidebar from "../../components/Sidebar";
import "./report.css";

function Reports() {
  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content-container">
        <div className="w-100 d-flex justify-content-center ">
          <div className="w-100">
            <h1 className="header">Reports</h1>
            <ReportsOverview />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
