import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import authService from "../services/auth.service";
function MilestonePopup(props) {
  let [currentTitle, setCurrentTitle] = useState(props.title);
  let [description, setDescription] = useState(props.description);
  useEffect(() => {
    setCurrentTitle(props.title);
    setDescription(props.description);
  }, [props.title, props.description]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <input
              className="sub-header minimal-input"
              onChange={(e) => {
                e.preventDefault();
                setCurrentTitle(e.target.value);
              }}
              value={currentTitle}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Priority: {props.priority}</p>
          <p>Status: {props.status || ""}</p>
          <p>Contributor: {props.contributor}</p>
          <p>Description</p>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-100"
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          {authService.getCurrentUser().roles.includes("ROLE_ADMIN") ? (
            <FontAwesome
              className="poke pointer"
              onClick={() => {
                props.handlePoke(props.id);
                props.handleSubmit({
                  title: currentTitle,
                  description: description,
                  id: props.id,
                });
              }}
              style={{ marginRight: "auto" }}
              size="2x"
              name="hand-pointer"
            />
          ) : null}
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            className="modal-submit-btn"
            variant="primary"
            onClick={() => {
              props.handleSubmit({
                title: currentTitle,
                description: description,
                id: props.id,
              });
            }}
          >
            {props.buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MilestonePopup;
